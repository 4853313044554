import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"
import PortfolioPageNav from "../components/portfolio-page-nav"

const PortfolioPageTemplate = ({ data, location }) => {
  const item = data.markdownRemark
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const { previous, next } = data

  const renderTags = () => {
    if (!item.frontmatter.tags) return; 

    return (
      <ul className="tag-list">
      {item.frontmatter.tags.map((tag, index) => { 
        return <li key={`tag-${index}`} className="tag-list-item">{tag}</li>
      })}
      </ul>
    )
  }

  const renderLink = () => {
    if (!item.frontmatter.url) return; 

    return (
      <a className="button" href={item.frontmatter.url}>Visit site</a>
    )
  }
  const renderImage = () => {
    if (!item.frontmatter.featuredImage) return;

    return (
      <Img
        className="screenshot"
        style={{ margin:'0 auto var(--spacing-4)' }}
        fluid={item.frontmatter.featuredImage.childImageSharp.fluid}
      />
    )
  }

  return (
    <Layout location={location} title={siteTitle}>
      <SEO
        title={item.frontmatter.title}
        description={item.frontmatter.summary || item.excerpt}
      />
      <article
        className="portfolio-page"
        itemScope
        itemType="http://schema.org/Article"
      >
        <header>
          {renderImage()}
          <h1 itemProp="headline">{item.frontmatter.title}</h1>
          <div className="portfolio-item__meta">
            {renderLink()}
            {renderTags()}
          </div>
        </header>
        <section
          dangerouslySetInnerHTML={{ __html: item.html }}
          itemProp="articleBody"
        />
      <PortfolioPageNav previous={previous} next={next} />
      </article>
    </Layout>
  )
}

export default PortfolioPageTemplate

export const pageQuery = graphql`
  query PortfolioItemByOrder(
    $id: String!
    $previousItemId: String
    $nextItemId: String
  ) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(id: { eq: $id }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        url
        summary
        tags
        featuredImage {
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    previous: markdownRemark(id: { eq: $previousItemId }) {
      fields {
        slug
      }
      frontmatter {
        title
        featuredImage {
          childImageSharp {
            fixed(width: 40, height: 30, quality: 80) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    }
    next: markdownRemark(id: { eq: $nextItemId }) {
      fields {
        slug
      }
      frontmatter {
        title
        featuredImage {
          childImageSharp {
            fixed(width: 40, height: 30, quality: 80) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    }
  }
`
