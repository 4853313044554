import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"

const PortfolioItemNav = ({previous, next}) => {
  
  let prevImage
  if (previous && previous.frontmatter.featuredImage) {
    prevImage = (
      <Img
        fixed={previous.frontmatter.featuredImage.childImageSharp.fixed}
      />
    )
  }

  let nextImage
  if (next && next.frontmatter.featuredImage) {
    nextImage =  (
      <Img
        fixed={next.frontmatter.featuredImage.childImageSharp.fixed}
      />
    ) 
  }
  

  return (
    <nav className="portfolio-page-nav">
      <h2>Other Projects</h2>
      <ul>
        <li>
          {previous && (
            <Link to={previous.fields.slug} rel="prev">
              ← {previous.frontmatter.title} {prevImage}
            </Link>
          )}
        </li>
        <li>
          {next && (
            <Link to={next.fields.slug} rel="next">
              {nextImage} {next.frontmatter.title} →
            </Link>
          )}
        </li>
      </ul>
    </nav>
  )
}

export default PortfolioItemNav;